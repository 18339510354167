.LoginPage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg-default);
  color: var(--typo-primary);

  .err{
    color: red;
  }

  &__form {
    background: var(--white);
  }
}