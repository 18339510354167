.Footer {
  display: flex;
  padding: 1.5vw;
  background-color: var(--bg-secondary);
  position: relative;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: var(--white);

  @media screen and (max-width: 768px){
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mid{
    @media screen and (max-width: 768px){
      display: none;
    }
  }

  p{
    font-family: 'Montserrat';

    @media screen and (max-width: 768px){
      font-size: 16;
    }
  }
  
}
