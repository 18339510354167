.Profile {
  background-color: var(--bg-default);
  width: 100vw;
  min-height: calc(100vh - 180px);
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: var(--text-primary);

  span {
    font-size: 19px;
  }

  img {
    height: auto;
    border-radius: 50px;
  }

  legend {
    span {
      font-size: 1em;
    }
  }

  .Head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiBox-root {
      background-color: transparent !important;

      svg {
        fill: var(--text-primary) !important;
      }
    }

    .MuiFormLabel-root {
      color: var(--text-primary) !important;
    }

    .MuiInputBase-root {
      color: var(--text-primary) !important;

      fieldset {
        border-color: var(--text-primary) !important;
      }
    }
  }

  .closeIcon {
    position: absolute;
    right: 1vw;
    top: 1vw;
  }

  .MuiPaper-root {
    margin-bottom: 0 !important;
  }

  .btns {
    display: flex;
    margin-left: auto;
  }

  .tools {
    display: flex;
    margin-top: auto;
    gap: 8rem;
  }

  &__Profile {
    display: flex;
    margin: auto;
    width: fit-content;
    gap: 15px;
    border: 1px solid var(--text-primary);
    padding: 1vw;
    position: relative;
  }

  &__ProfileCard {
    display: flex;
    margin: auto;
    width: fit-content;
    gap: 15px;
    border: 1px solid var(--text-primary);
    padding: 1vw;
    position: relative;

    @media screen and (max-width: 768px) {
      flex-direction: column;

      img {
        margin: auto;
      }

      .tools {
        margin-top: 25px;
      }
    }

    .info {
      height: auto !important;
    }

    .MuiBox-root {
      background-color: transparent !important;
    }

    .MuiFormLabel-root {
      color: var(--typo-primary);
    }

    .MuiInputBase-root {
      color: var(--typo-primary);

      &:hover {
        fieldset {
          border: 2px solid var(--bg-primary-purple);
        }
      }

      fieldset {
        border: 1px solid white;
      }
    }

    .select__menu {
      overflow: hidden !important;
      height: 200px !important;
    }

    .Property {
      color: var(--bg-primary-purple);
      padding-bottom: 2px;
      border-bottom: 1px solid var(--bg-primary-purple);
    }
  }

  &__CreateProfile {
    display: flex;
    margin: auto;
    width: fit-content;
    gap: 15px;
    border: 1px solid var(--text-primary);
    padding: 1vw;
    position: relative;
    background-color: var(--bg-primary-purple);
    border-radius: 15px;

    .err{
      text-align: center;
      color: red;
    }

    .basic-multi-select {
      margin-bottom: 0 !important;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 4vw;

      img {
        display: none;
      }

      .tools {
        margin-top: 25px;
      }
    }
    @media screen and (min-width: 1921px) {
      .basic-multi-select {
        min-width: 10vw;
        margin-bottom: 0 !important;
      }
      .Select {
        width: 18vw;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    button {
      display: flex;
      margin: auto;
    }

    .closeIcon {
      position: absolute;
      right: 0.4rem;
      top: 0.4rem;
      fill: white;
    }

    /* .info{
      display: flex;
      flex-wrap: wrap;
    } */

    .MuiFormLabel-root {
      color: var(--typo-primary);
    }

    .MuiInputBase-root {
      color: var(--typo-primary);

      fieldset {
        border: 1px solid white;
      }
    }
  }

  .info {
    width: 35rem;
    display: flex;
    flex-direction: column;
    height: 26vw;
  }

  .Spoiler {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition:
      opacity 0.5s ease-in-out,
      max-height 0.5s ease-in-out;

    &.show {
      max-height: 12rem;
      opacity: 1;
      overflow: auto;
    }
  }
}
