.BarChart{
   
    width: 100%;
    height: 65%;
    

    @media screen and (max-width: 768px) {
        height: 100%;
    }

    .custom-tooltip {
        background-color: rgb(#ffffff,0.3);
        white-space: pre-wrap !important;
        padding: 0px 10px;
        border-radius: 8px;
    }

    text{
        fill: var(--text-primary);
    }

    svg{
        overflow: unset;
    }

   /*  .labelz{
        transform: rotate(-90deg);
    } */
}