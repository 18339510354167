.CardWrapper {
  background-color: var(--bg-secondary);
  padding: 2vw;
  height: 35vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;

  @media screen and (max-width: 768px){
    width: 100%;
    height: 80vh;

    &.Widget{
      height: 35vh;
    }
  }

  /* @media screen and (min-width: 768px){
    height: 35vh;
  } */

  &__hide {
    min-height: 100px !important;
    height: 100px !important;
  }

  &__icon {
    position: absolute;
    right: 1vw;
    top: 1vw;
  }

  .MuiSvgIcon-fontSizeSmall {
    position: absolute;
    top: 1vw;
    cursor: pointer;

    @media screen and (max-width: 768px){
     right: 2vw;
    }
    @media screen and (max-width: 1439px){
      right: 5vw;
     }

     @media screen and (min-width: 1440px){
      right: 3vw;
     }
  }

  .MuiSvgIcon-fontSizeMedium{
    @media screen and (max-width: 768px){
      display: none;
    }
  }

  &__toolsBar{
    position: absolute;
    right: 1vw;
    top: 1vw;
  }

  &__close-btn {
    position: absolute;
    right: 2vw;
    top: 2vw;
  }

  &.BarVertical{
    height: 60vw;
    padding: 2vw; 
    padding-left: 3vw;

    @media screen and (max-width: 700px){
      height: 120vh;
    }
  }

  &.Heatmap{
    overflow: hidden;
    height: 20vw;
  }

  &.Table{
    padding: 3vw;
    height: 45vh;
  }
}
