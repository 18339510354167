.Table {
  background-color: var(--grey-light);
  

  .MuiTableContainer-root{
    height: 100%;
  }

  .MuiTableHead-root {
    background-color: var(--bg-primary-hovered) !important;
    width: 100%;
  }

  .MuiTableCell-head{
    color: var(--white);
  }

  .Mui-active {
    color: var(--white) !important;

    svg{
      color: var(--white) !important;
    }
  }

  .MuiTableRow-head{
    color: var(--text-primary);
  }

  .column{
    display: flex;
  }

  .MuiToolbar-gutters{
    padding: 0;
    background-color: var(--bg-secondary) !important;
  }

  .MuiTablePagination-toolbar{
    color: var(--text-primary);

    .MuiSelect-iconStandard{
      fill: var(--text-primary);
    }
  }
}
