.MainPage {
  width: 100vw;
  height: 100%;
  min-height: calc(100vh + 85px);

  background-color: var(--bg-primary);
  color: var(--text-primary);

  .selectMain__menu {
    z-index: 999;
    background-color: var(--bg-secondary) !important;
  }

  .selectMain__input-container {
    color: var(--text-primary);
  }

  &__settings {
    width: fit-content;
    margin-left: auto;
    margin-right: 2vw;
    @media screen and (max-width: 768px) {
      margin: 2vw;
      width: 96%;

      .MuiBox-root {
        justify-content: space-evenly;
        align-items: center;
      }
    }
  }

  &__top {
    width: 100%;
    padding: 1vw;
    display: flex;
    justify-content: space-between;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 1vh;
    border-bottom: 1px solid var(--bg-secondary);

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 1vw;
    }

    .selectMain {
      margin-bottom: 1vw;
      min-width: 25vw;

      &__menu {
        background-color: var(--bg-primary);
        color: var(--text-primary);
        z-index: 3;
      }

      &__control {
        background-color: var(--bg-primary);
        color: var(--text-primary);
      }

      &__single-value {
        color: var(--text-primary);
      }
    }
  }

  &__dashBoard {
    margin: 2vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 1vw;
    padding-bottom: 5vh;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &.oneline {
      display: flex;
      flex-direction: column;

      .CardWrapper {
        height: 50vh;
      }

      .Card {
        min-height: fit-content;
      }
    }

    &.mix {
      display: flex;
      flex-wrap: wrap;
      gap: 2vw;

      .CardWrapper {
        min-height: 365px;
        width: 100%;

        &.AreaGis {
          width: 48.8%;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }

        &.LineGis {
          width: 48.8%;
          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }

        &.Bar {
          height: 60vw;

          @media screen and (min-width: 1600px) {
            height: 42vw;
          }

        /*   @media screen and (min-width: 1601px) {
            height: 65vh !important;
          } */
        }

        &.ksg{
          height: 55vh;
        }
        &.Heatmap{
          @media screen and (max-width: 1024px) {
            min-height: 200px !important;
          }
         
        }

        &.Widget {
          width: fit-content;
          height: auto;
          min-height: 200px;

          .Card {
            min-height: 160px;
          }

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }

        &.Pie3 {
          width: 48.8%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          @media screen and (min-width: 1301px) and (max-width: 1900px) {
            height: 30vw;
          }

          @media screen and (min-width: 769px) and (max-width: 1300px) {
            height: 35vw;
          }

          @media screen and (min-width: 1901px) {
            height: 25vw;
          }
        }

        &.Pie2 {
          height: 85vh;

          &.CardWrapper__hide {
            height: fit-content;
          }

          @media screen and (min-width: 769px) {
            height: 55vw;
          }
          @media screen and (min-width: 1281px) {
            height: 45vw;
          }

          @media screen and (min-width: 1441px) {
            height: 35vw;
          }
        }
        &.Pie4 {
          height: 85vh;

          &.CardWrapper__hide {
            height: fit-content;
          }

          @media screen and (min-width: 768px) {
            height: 65vh;
          }
        }

        &.Pie {
          width: 31.8%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    &.slider {
      min-height: 67.5vh;
      flex-direction: column;
      display: flex;

      .slick-arrow{
        &::before{
          color: var(--text-primary) !important;
        }
        
      }

      .CardWrapper {
        width: 90%;
        margin: auto;
        height: 45vw;

        @media screen and (max-width: 767px) {
          width: 99%;
          height: 80vh;
        }

        &.Widget {
          width: fit-content;
          height: fit-content;
          border-radius: 25px;

          @media screen and (min-width: 767px) {
            margin-left: 4.5vw;
            border-radius: 25px;
            margin-right: 4.5vw;
          }
        }

        &.Pie2 .recharts-legend-wrapper {
          left: 50% !important;

          @media screen and (min-width: 1279px) {
            left: 12% !important;
          }

          /* .legend {

            @media screen and (min-width: 1279px) {
            width: 30vw;
            }
          } */
          .legend {
            text-align: center;
            overflow: auto;
            height: 20vw;
            width: 35vw;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            overflow-x: hidden;
      
            @media screen and (min-width: 300px) and (max-width: 767px) {
              width: 85vw;
              height: 100vw;
            }
      
            @media screen and (min-width: 768px) and (max-width: 1024px) {
              width: 85vw;
              height: 55vw;
            }
      
            @media screen and (min-width: 1280px) {
              width: 22vw;
              height: 35vw;
            }
      
            @media screen and (min-width: 1441px) {
              width: 22vw;
              height: 25vw;
            }
          }
        }
        
      
        .legendRow{
          color: var(--text-secondary);
          @media screen and (max-width: 767px) {
            opacity: 1 !important;
          }
        }
        

        &.Heatmap {
          height: 22vw;
          margin-top: 15%;
          min-height: 200px;
        }
      }

      .slider-container {
        margin-top: auto;
        margin-bottom: auto;
      }

      .slick-prev {
        left: 1vw;
        z-index: 2;
        width: auto;
        height: auto;

        @media screen and (max-width: 767px) {
          display: none !important;
        }

        &:before {
          font-size: 50px;
        }
      }
      .slick-next {
        right: 1vw;
        z-index: 2;
        width: auto;
        height: auto;

        @media screen and (max-width: 767px) {
          display: none !important;
        }

        &:before {
          font-size: 50px;
        }
      }

      .slick-dots li button:before {
        color: var(--text-primary);
      }
    }

    @media screen and (min-width: 1920px) {
      grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
      gap: 2vw;
    }

    &.selects {
      grid-template-columns: 2fr 1fr 1fr;
    }
  }
}
