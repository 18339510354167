.BarChartVertical{
    width: 100%;
    height: 100%;
    margin: auto;

    .custom-tooltip{
        border: 1px solid var(--bg-primary-hovered);
        border-radius: 14px;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        background-color: var(--bg-secondary);
        align-items: center;
        flex-direction: row;
        max-width: 700px;
    }

    text{
        fill: var(--text-primary);
    }

    /* line{
        stroke: var(--text-primary);
    } */

    .recharts-label{
        text-anchor: middle;
    }

    .MuiFormControl-root {
        position: absolute;
        left: 15vw;
        top: 0;

        @media screen and (max-width: 768px) {
            display: none;
          }

        label{
            color: var(--text-primary) !important;
        }
    
        .MuiOutlinedInput-notchedOutline{
            border-color: var(--text-primary);
        }

        input{
            color: var(--text-primary);
        }
    }
}