.PieChart2 {
  width: 100%;
  height: 100%;
  position: relative;

  @media screen and (max-width:1440px) {
    height: 95%;
  }

  &.mobile{
    @media screen and (max-width: 1280px) {
      .recharts-layer{
        display: none;
      }
    }
  }

  .recharts-layer{
    font-size: 12px;
  }

  text {
    fill: var(--text-third) !important;

    @media screen and (min-width: 1440px) {
      font-size: 13px !important;
    }
    @media screen and (min-width: 1800px) {
      font-size: 16px !important;
    }
    @media screen and (min-width: 1920px) {
      font-size: 18px !important;
    }
  }

  .MuiPaper-root {
    box-shadow: none;
  }

  .recharts-wrapper {
    color: white;
  }

  .recharts-pie{
    cursor: pointer;
  }

  .recharts-legend-wrapper {
    display: block;
    position: absolute;
    width: fit-content !important;
    bottom: 45px !important;
    top: 38% !important;
    left: 19.1% !important;
    transform: translate(-50%,-50%);
    font-family: "Montserrat" !important;

    @media screen and (min-width: 300px) and (max-width: 1024px) {
      left: 50% !important;
      top: 40% !important;

      .MuiTypography-root{
        font-size: 11px !important;
        font-family: "Montserrat" !important;
      }
    }

    @media screen and (min-width: 1280px) {
      left: 13.1% !important;

      .MuiTypography-root{
        font-size: 18px !important;
        font-family: "Montserrat" !important;
      }
    }

    .legend {
      text-align: center;
      overflow: auto;
      height: 20vw;
      width: 35vw;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      overflow-x: hidden;

      @media screen and (min-width: 300px) and (max-width: 767px) {
        width: 85vw;
        height: 100vw;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 85vw;
        height: 55vw;
      }

      @media screen and (min-width: 1280px) {
        width: 25vw;
        height: 35vw;
      }

      @media screen and (min-width: 1441px) {
        width: 25vw;
        height: 25vw;
      }
    }
  }

  .legendRow{
    color: var(--text-secondary);
    @media screen and (max-width: 767px) {
      opacity: 1 !important;
    }
  }
}
