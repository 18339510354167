.AreaChart{
    width: 100%;
    height: 95%;

    .recharts-default-tooltip{
        white-space: pre-wrap !important;
        color: black;
    }

    .recharts-tooltip-wrapper{
        width: 270px;
    }

    .custom-tooltip {
        white-space: pre-wrap !important;
        padding: 0px 10px;
        border-radius: 8px;
        color: white;
        width: 80%;
    }

    text{
        fill: var(--text-primary);
    }

    .recharts-label{
        text-anchor: middle;
    }
}