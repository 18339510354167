.Card {
  width: 100%;
  height: 100%;
  min-width: 200px;
  min-height: 200px;
  /*  display: flex;
    align-items: center;
    justify-content: center; */
  position: relative;
  color: var(--text-secondary);

  @media screen and (max-width: 768px){
    width: 90%;
    height: 90%;

    .WidgetCard__numbers{
      font-size: 60px;
    }
  }

  @media screen and (min-width: 300px){
    .WidgetCard__numbers{
      font-size: 50px;
    }
  }

  /* .MuiSvgIcon-root {
    position: absolute;
    right: 2vw;
    top: 2vw;
  } */

  .MuiTypography-root {
    color: var(--text-secondary);
  }
}
