.WidgetCard{
    width: 100%;
    height: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */

    .MuiTypography-caption{
        margin-bottom: 2vw;
        font-size: 30px;
    }

    &__numbers{
        font-size: 80px;
        color: var(--text-primary);
    }
}