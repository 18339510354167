.Calendar {
  @media screen and (max-width: 768px){
    margin-top: 2vw;

    .MuiInputBase-root{
      margin-bottom: 2vw;
    }
  }
  .MuiInputBase-root{
    color: var(--text-primary);
  }

  button{
    background-color: var(--bg-secondary) !important;
    color: var(--text-primary);
    border: 1px solid var(--text-secondary) !important;
  }

  .MuiOutlinedInput-notchedOutline{
    border-color: var(--redPurple) !important;
  }

  .MuiFormLabel-root {
    color: var(--text-primary) !important;
  }
}

  .MuiPaper-root{
    background-color: transparent;
    border-radius: 32px;
    .MuiList-root {
      padding: 0px !important;
      
      .react-calendar{
        background: rgba(53, 56, 89, 1);
        border: 2px solid rgba(53, 56, 89, 1);
    
        color: white;
    
        abbr{
          text-decoration: none;
        }
        button{
          color: rgb(128, 191, 255);
        }
    
        .react-calendar__tile--active{
          color: white;
          background: rgb(255,137,51) !important;
        }
      }
    }
    
  }


