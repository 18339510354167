.PieChart3 {
  width: 100%;
  height: 95%;

  text {
    fill: var(--text-primary) !important;
  }

  .recharts-wrapper {
    .custom-tooltip {
      background-color: #070101;
      white-space: pre-wrap !important;
      padding: 0px 10px;
      border-radius: 8px;
      color: white;
    }

    .recharts-sector{
      stroke: var(--text-primary) !important;
    }

    .recharts-legend-wrapper{
      max-width:20%;
    }
  }
}
