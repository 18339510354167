.SelectSearch{
    height: fit-content;
    background-color: var(--bg-secondary);
    display: flex;
    flex-direction: column;
    width: 100%;

    .select__menu{
        background-color: var(--bg-secondary);
        color: var(--text-primary);
    }

    .select__control{
        background-color: var(--bg-secondary);
        color: var(--text-primary);
        border-radius: 8px;
    }

    .basic-multi-select{
        margin-bottom: 1vw;
        
    }
    button{
        color: var(--text-primary);
        border: 1px solid var(--text-primary);
    }

    .select__single-value{
        color: var(--text-primary);
    }
}