:root {
  --bg-default: #1a1a1a;
  --bg-primary-purple: rgba(53, 56, 89, 1);
  --bg-secondaryy: #F5F5F5;

  --bg-primary-hovered: #5d2df5;

  --bg-hovered: rgba(128, 128, 128, 0.16);
  --bg-selected: rgba(144, 202, 249, 0.16);

  --white: #FFFFFF;
  --text-red:#C93239;
  --grey: #383A3F;

  --bg-primary-light: #4068e7;
  --grey-light: rgba(168, 168, 168, 0.25);

  --box-shadow-light: rgba(212, 216, 222, 0.2);

  --typo-primary: #ffffff;

  --disabled-color: #c5c5c5;

  --common-border-radius: 4px;

  --footer-bg2: rgba(56, 58, 63, 1);

  --footer-bg1: rgba(53, 56, 89, 1);
}

/* *{
  outline: 4px solid green !important;
} */

.App {
  text-align: center;
  height: 100vh;
}

.light-theme {
  --text-primary: var(--text-red);
  --text-secondary: var(--grey);
  --text-third: var(--bg-default);
  /* --bg-primary: var(--bg-secondaryy); */
  --bg-secondary: var(--bg-secondaryy);
  --bg-navbar: var(--bg-navbar-light);
  --footer-bg:var(--footer-bg1);
  --redPurple:var(--text-red);
}

.dark-theme {
  --text-primary: var(--typo-primary);
  --text-secondary: var(--white);
  --text-third: var(--white);
  --bg-primary: var(--bg-default);
  --bg-secondary: var(--bg-primary-purple);
  --bg-navbar: var(--bg-navbar-dark);
  --footer-bg:var(--footer-bg2);
  --redPurple:var(--bg-primary-purple);
}

html {
  height: 100%;
}

body {
  background-color: var(--bg-primary);
  overflow-x: hidden;
  

  #root {
    height: 100%;
  }
}

#modal-profile{
  background-color: transparent;
  display: flex;

  .MuiBox-root{
    background-color: var(--bg-primary-purple) !important;
    color: white;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #424242;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #686868;
  border-radius: 6px;
}

header {
  @media screen and (max-width: 768px) {
   
    .logo{
      width: 10%;
      height: 10%;
    }
    .lebel{
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .user {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }

  
  .logo{
    fill: var(--text-primary);
  }

  .theme{
    svg{
      fill: var(--text-primary);
    }
  }
}
.MuiBox-root{
  background-color: var(--bg-secondary) !important;
}


  .MuiAppBar-root{
    background-color: var(--bg-secondary) !important;
    color: var(--text-third) !important;
   

    .MuiTypography-root{
      font-family: 'Montserrat';
    }
  }


.settingsBox{
  background-color: var(--bg-secondary) !important;

  svg{
    fill: var(--text-primary);
  }
}

.MuiModal-root{
  .BarChartVertical{
    .recharts-wrapper{
    text{
      font-size: 14px !important;
      @media screen and (min-width: 1640px) {
        font-size: 16px !important;
      }
    }
  }
  }
  .recharts-wrapper{
    text{
      
      font-size: 14px !important;
      @media screen and (min-width: 1640px) {
        font-size: 21px !important;
      }
      
    }
  }
  .recharts-legend-wrapper{
    left: 12% !important;
    @media screen and (min-width: 1440px) {
      left: 16% !important;
    }
  }
  .legend{
    width: 20vw !important;
    height: 30vw !important;
   
    .MuiTypography-root{
      font-size: medium !important;
      @media screen and (min-width: 1440px) {
        font-size: smaller !important;
      }
      
    }
    @media screen and (min-width: 1440px) {
      width: 28vw !important;
      height: 35vw !important;
    }
  }
}
