.PieChartWrap {
  width: 100%;
  height: 100%;

  text{
    fill: var(--text-primary) !important;
  }

  .MuiPaper-root{
    box-shadow: none;
    background-color: var(--bg-secondary);
  }
}

.MuiModal-root{
  .PieChartWrap {
    width: 95%;
    height: 95%;
  }
}