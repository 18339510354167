.Heatmap {
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1280px){
    height: 60%;
    margin-top: 3vw;
  }

  .color-null{
    fill: #33FF99;
  }

  .color-50{
    fill: #00CC66;
   }

   .color-100{
    fill: #009900;
  }

  .color-150{
    fill: #c56d1a;
  }

  .color-200{
    fill: rgb(189, 58, 35);
  }

  .legend{
    display: flex;
    width: 25%;
    gap: 15px;

    .c0{
      display: flex;
      align-items: center;
      &::before{
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: #33FF99;
        margin-right: 5px;
      }
    }
    .c50{
      display: flex;
      align-items: center;
      &::before{
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: #00CC66;
        margin-right: 5px;
      }
    }

    .c100{
      display: flex;
      align-items: center;
      &::before{
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: #33FF99;
        margin-right: 5px;
      }
    }
    .c150{
      display: flex;
      align-items: center;
      &::before{
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: #c56d1a;
        margin-right: 5px;
      }
    }
    .c200{
      display: flex;
      align-items: center;
      &::before{
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: rgb(189, 58, 35);
        margin-right: 5px;
      }
    }
  }
}
