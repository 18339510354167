.MainSelect{
    height: fit-content;
    padding: 2vw;
    background-color: var(--bg-secondary);
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    .select__menu{
        background-color: var(--bg-secondary);
        color: var(--text-primary);
    }

    .select__control{
        background-color: var(--bg-secondary);
        color: var(--text-primary);
    }

    .select__input-container{
        color: var(--text-primary);
    }
    .select__placeholder{
        color: var(--text-primary);
    }


    .basic-multi-select{
        margin-bottom: 1vw;
        min-width: 25vw;
    }
    button{
        color: var(--text-primary);
        border: 1px solid var(--text-primary);
    }
}